let config = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "forwood-ozminerals-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-ozminerals-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "ap-southeast-2",
        URL: "https://ddrvwhgczbeffc2zaxzoy3gija.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://cnhy2x6ju4.execute-api.ap-southeast-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://2haf40mph8.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.ozminerals.forwoodsafety.com",
        WEBSOCKET: "wss://wg3taxg1u2.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_8ygXVgFwA",
        IDENTITY_POOL_ID: "ap-southeast-2:9ed66f9b-8ced-449b-bdb5-6383cc94aa04",
        USERPOOL_HOSTED_DOMAIN: "forwood-ozminerals-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.ozminerals.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.ozminerals.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::325507275841:role/prod-CA"
    },
    samlProvider: {
      NAME: 'bhp',
      SUPPORTED_LIST: 'bhp,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.36.2",
        HOSTNAME: "id.ozminerals.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".ozminerals.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "3f894ed8-00cb-4310-8045-1bbe387554b0",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.ozminerals.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
